import { Injectable } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
//import { HttpClient } from '@angular/common/http';  
import { HttpClient, HttpErrorResponse,HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, retry,timeout } from 'rxjs/operators';
import { NumericDictionary } from 'lodash';
import { map , filter} from 'rxjs/operators';
//import { NgxSpinnerService } from 'ngx-spinner';
//import { Product } from '../models/app.models';
@Injectable({
  providedIn: 'root'
})

export class AppQuerysService {

  
  constructor(public settings: AppSettingsService, public http: HttpClient) { }
  public nTimeout:number=20000;
  public nRetry:number=5;
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        'Backend returned code , ' +error.status+
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    //this.spinner.hide();
    //this.snackBar.open('Algo ocurrio por favor trate nuevamente.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    return throwError('Algo ocurrio por favor trate nuevamente.');
    
  };
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
    //Authorization: this.authService.getToken()
  })
  public getAuthUser(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAuthUser');
    return  this.http.post(url,request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


  public getDomains(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getDomains')+request;

    
    
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


public updDomain(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updDomain');
    return  this.http.post(url,request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public setDomain(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setDomain');
    
    return  this.http.post(url,request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getParameters(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getParameters');
    
    return  this.http.post(url,request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  //----------------------------TypeVisits------------------//
getTypeVisits(  ){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_typeVistis');
  return  this.http.get(url).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );
}

getTypeuser(){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_typeUser');
  return  this.http.get(url).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );
}
//---------------------------------------------------//

//------------------------------User Acs-----------------------------------//
getUsersAcs(){ //api_getUserByRol
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getUserAcs');
  return  this.http.post(url, {},{ headers: this.headers }).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  )
}

getUserByRol(request:any){ //api_getUserByRol
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getUserByRol');
  console.log('url ',url);
  
  return  this.http.post(url, request,{ headers: this.headers }).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  )
}

setUserAcs( request:any ){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setUserAcs_getData'); 
    
    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )

}

updUserAcs( request:any){
   
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updUserAcs_getData')
  return  this.http.post(url,request,{ headers: this.headers }).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );

}



  //--------------------------------------------------------//

                                     //---------------------users----------------//
  public getUsers(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getUsers');
    return  this.http.get(url,request).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setUser( request:any ){
    
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setUsers'); //+`/${request.c_name}/${request.c_lastname}/${request.c_age}/${request.c_address}/${request.c_status}/${request.c_password}/${request.c_dni}`;    
    console.log('url de seteo: ',url);
    
    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )

  }

  public updUser(request: any){

    //console.log('actulizar usur: ', request );
    
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updUser')//+`${request.id}/${request.c_name}/${request.c_lastname}/${request.c_age}/${request.c_address}/${request.c_status}/${request.c_password}/${request.c_dni}`;    
    return  this.http.post(url,request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  
  public updUserWithoutPass(request: any){

    //console.log('actulizar usur: ', request );
    
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updUserWithoutPass_getData')
    console.log('url setear sin contraseña: ',url);
    
    return  this.http.post(url,request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  
  getTypeuserForUsersCombo(){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getTypeUsers');
    return  this.http.post(url,{},{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


                                  //-------------------------------------//
  //-----------------------------Visits-----------------------------------//
  
  getVisits( id:number ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getVisits')+'/'+id;
    console.log('visita de usuario scd elegido: ', url);
    
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  getVisitsWithoutId(){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getVisits')+'/-'
    console.log('url getVisits/-: ', url);
    
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


  setVisit( request:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setVisit_getData'); 
    console.log(url);
    
    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )
  }


  updVisit(  request:any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updVisit_getData') 
    console.log('url de actualizar: ',url);
    
    return  this.http.post(url,request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }




  deleteVisit(request:any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delVisit_getData') +`/${request.id}/${request.iduseracs}/${request.status}`;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

//------------------------------------------------------------------------//

  //-----------------------------Frecuently Questions------------------------//
  getFrecuentlyAsks(request:any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getFrecuentlyAsk')
    return  this.http.get(url,request).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  setFrecuentlyAsk( request:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setFrecuentlyAsk_getData')
    return  this.http.post(url,request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  updFrecuentlyAsks( request:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updFrecuentlyAsk_getData')
        return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )
  }

  deleteFrecuenltyAsk( request:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delFrecuentlyAsk_getData') 
    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )

  }
  
  //--------------------------------------------------------------------------//

  //--------------------------------------WorkFlow-------------------------------------------//
  getWorkFlowList( request:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getWorkFlows')
    return  this.http.get(url,request).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  setworkFlow( request:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setWorkFlows_getData')
    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )
  }

  updWorkFlow( request:any ){
    const url = this.settings
    .getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updWorkFlows_getData')
    console.log('url de work flow: ',url);
    

    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )
  }

  deleteWorkFlow(request:any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delWorkFlows_getData') +`/${request.id}/${request.status}`;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  activateWorkFlow(request:any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_actWorkFlows_getData') +`/${request.id}/${request.status}`;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  //---------------------------------------------------------------------------------------------//

  //------------------------ Roles --------------------//
  getRoles(request:any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getRoles')
    return  this.http.get(url,request).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }



  getUserRoles(request: any){

    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getUserRole')
    return  this.http.get(url,request).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  setUserRol(  request:any  ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setUserRole_getData')
    console.log('url set: ',url);
    
    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )

  }
  
  updUserRol( request:any ){
    
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updUserRole_getData')
    console.log('la url de actulizar:' , url);
    

    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )
  }


  deleteUserRol( request:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delUserRole_getData') ;
    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )

  }

  //----------------------------------------------------//


  //--------------Zones------------//
  getZonesList(request){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getZones' ) ;
    console.log('url para traer zonas: ',url );
    
    return  this.http.get(url,request).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


   setZone( request:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setZone_getData')
    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )


    }

    updZone( request:any ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updZone_getData')
      return  this.http.post(url, request,{ headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      )
    }





  //---------------------------------//


  //----------------------------------USER ROLES----------------------------//
  getUserZones(){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getUserZones')
    return  this.http.get(url,{}).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  setUserZone(  request:any  ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setUserZone_getData')
    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )

  }

  updUserZone( request:any ){
    
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updUserZone_getData')
    

    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )
  }

  deleteUserZone( request:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delUserZone_getData') ;
    return  this.http.post(url, request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    )

  }





  //-------------------------------------------------------------------------//
//------------------User Citizen----------------------//

getTypeCitizen(){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getTypeCitizen');
  console.log('esta es la url de usuario citizen: ',url);
  
  return  this.http.post(url,{},{ headers: this.headers }).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );
}

getUsersCitizen(){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getUsersCitizen')
  return  this.http.post(url,{},{ headers: this.headers }).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );
}


setUsersCitizen( request:any ){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setUsersCitizen')
  console.log('esta es la url para setear: ',url);
  
  return  this.http.post(url,request,{ headers: this.headers }).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );
}

updUsersCitizen( request:any ){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updUsersCitizen')
  return  this.http.post(url,request,{ headers: this.headers }).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );
}




//---------------------------------------------------------------//

  public updParameter(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updParameter');
    return  this.http.post(url,request,{ headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  
  public getParametersByDomain(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getParametersByDomain')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getClinicHistoryByIdUser(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getClinicHistoryByIdUser')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getClinicHistory(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getClinicHistory')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getAllDiagnostics(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllDiagnostics')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getDiagnostics(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getDiagnostics')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getAllDiagnosticsByFollow(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllDiagnosticsByFollow')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setNutritionMonitorings(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setDiagnosticsNutritionist')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updDiagnostics(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updDiagnostics')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updClinicHistoryPast(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updClinicHistoryPast')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

    public getPatientsForNutritionist(request: any){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getClinicHtaNutritionist')+request;
      return  this.http.get(url).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  public updUserHta(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updUserHta')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  //#region UserDisease
  public getUserDisease(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getUserDiseases')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getDiseasesByIdUser(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getDiseasesByIdUser')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setUserDisease(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setUserDiseases')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updUserDisease(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updUserDiseases')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion 

  //#region Biological Analysis

  public getAllHistoryAnalysis(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllHistoryAnalysis')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getHistoryAnalysis(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getHistoryAnalysis')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setHistoryAnalysis(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setHistoryAnalysis')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updHistoryAnalysis(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updHistoryAnalysis')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion 


   //#region History Frecuency

   public getAllHistoryFrecuency(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllHistoryFrecuency')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getHistoryFrecuency(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getHistoryFrecuency')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setHistoryFrecuency(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setHistoryFrecuency')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updHistoryFrecuency(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updHistoryFrecuency')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion 

  //#region History Anamnesis
  public getAllHistoryAnamnesis(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllHistoryAnamnesis')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getHistoryAnamnesis(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getHistoryAnamnesis')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
 
  public setHistoryAnamnesis(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setHistoryAnamnesis')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updHistoryAnamnesis(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updHistoryAnamnesis')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion 

  //#region Food Habits
  public getAllFoodHabits(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllFoodHabits')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getFoodHabits(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getFoodHabits')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setFoodHabits(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setFoodHabits')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updFoodHabits(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updFoodHabits')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion 

  //#region Hta Food Habits
  public getAllHtaFoodHabits(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllHtaFoodHabits')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getHtaFoodHabits(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getHtaFoodHabits')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setHtaFoodHabits(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setHtaFoodHabits')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updHtaFoodHabits(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updHtaFoodHabits')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion 

  //#region HtaPhysicalActivity
  public getHtaPhysicalActivity(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getHtaPhysicalActivity')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setHtaPhysicalActivity(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setHtaPhysicalActivity')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updHtaPhysicalActivity(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updHtaPhysicalActivity')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion 

  //#region HtaClinicalSign
  public getHtaClinicalSign(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getHtaClinicalSigns')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setHtaClinicalSign(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setHtaClinicalSigns')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updHtaClinicalSign(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updHtaClinicalSigns')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion 

  //#region Follow History
  public getFollowHistory(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getFollowHistory')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getAllFollowHistory(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllFollowHistory')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  
  public getDataFollowHistoryItems(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getDataFollowHistoryItems')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public setFollowHistory(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setFollowHistory')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updFollowHistory(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updFollowHistory')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updFollowHistoryItems(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updFollowHistoryItems')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion

  //#region Follow Topic
  public getFollowTopic(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getFollowTopic')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getAllFollowTopic(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllFollowTopic')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setFollowTopic(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setFollowTopic')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updFollowTopic(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updFollowTopic')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public delFollowTopic(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delFollowTopic')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion

  //#region Goals Patient
  public getGoalPatient(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getGoalPatient')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getAllGoalPatient(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllGoalPatient')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setGoalPatient(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setGoalPatient')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updGoalPatient(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updGoalPatient')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public delGoalPatient(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delGoalPatient')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion

  //#region Reminders
   public getReminders(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getReminders')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getAllReminders(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllReminders')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setReminders(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setReminders')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public updReminders(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updReminders')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public delReminders(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delReminders')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion

  //#region Recipes
   public getRecipes(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getRecipes')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getAllRecipes(){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllRecipes');
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion

   //#region Caloric Requirement

  public getCalcCaloricRequirement(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getCalcCaloricRequirement')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion

   //#region Nutritional Plan

  public getNutritionalPlan(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getNutritionalPlan')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getAllNutritionalPlan(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllNutritionalPlan')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getNutritionalPlanByUserType(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getNutritionalPlanByUserType')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getDataNutritionalByUserType(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getDataNutritionalByUserType')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getAllNutritionalPlanByUser(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllNutritionalPlanByUser')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getNutritionalPlanDetail(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getNutritionalPlanDetail')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setNutritionalPlanPatient(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setNutritionalPlanPatient')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setNutritionalPlan(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setNutritionalPlan')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public updNutritionalPlan(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updNutritionalPlan')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public updNutritionalPlanDetail(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updNutritionalPlanDetail')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public delNutritionalPlan(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delNutritionalPlan')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion

 //#region TypeFoodPlan
public setTypeFoodPlan(request: any){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setTypeFoodPlan')+request;
  return  this.http.get(url).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );
}
//#endregion

//#region TypeFoodPlanDetails
public getAllTypeFoodPlanDetails(request: any){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllTypeFoodPlanDetails')+request;
  return  this.http.get(url).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );
}
public setTypeFoodPlanDetails(request: any){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setTypeFoodPlanDetails')+request;
  return  this.http.get(url).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );
}
public updTypeFoodPlanDetails(request: any){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updTypeFoodPlanDetails')+request;
  return  this.http.get(url).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );
}
public delTypeFoodPlanDetails(request: any){
  const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delTypeFoodPlanDetails')+request;
  return  this.http.get(url).pipe(
    timeout(this.nTimeout),
    retry(this.nRetry), // retry a failed request up to 3 times
    catchError(this.handleError) // then handle the error
  );
}
//#endregion



  //#region Nutritional Plan Day

  public getNutritionalPlanDay(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getNutritionalPlanDay')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getAllNutritionalPlanDay(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllNutritionalPlanDay')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setNutritionalPlanDay(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setNutritionalPlanDay')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public updNutritionalPlanDay(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updNutritionalPlanDay')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public delNutritionalPlanDay(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delNutritionalPlanDay')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion

   //#region Nutritional Meal Day

   public getNutritionalMealDay(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getNutritionalMealDay')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getAllNutritionalMealDay(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllNutritionalMealDay')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public setNutritionalMealDay(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setNutritionalMealDay')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public updNutritionalMealDay(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updNutritionalMealDay')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public delNutritionalMealDay(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_delNutritionalMealDay')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  //#endregion


  //consulta para obtener :Registro nacional de instituciones prestadoras d e servicio




  public getStablishment(data: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getStablisment');
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }




  setDisa( data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setDisa');
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


  updDisa( data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updDisa');
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  setStablishment( request:any ){
    return request
  }



  updStablishment( request:any ){
    return request
     }


     //------------------------------------Dental------------------------------//


    setPatient( data:any ){

    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_setPatient');

      console.log('url a consultar: ',url);
      
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  setOdontogram_getData( data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_setOdontogram_getData');
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  updOdontogram_getData( data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_updOdontogram_getData');
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  getHistoryOdontogram( data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_getHistoryOdontogram');
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  getOdontogram( data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_getOdontogram');
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  
  public getPatients(){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getPatients');
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }



  updPatient( id , data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updPatient_getData') + `?ID=${ id }` ;
    console.log('url upd patient: ', url );
    
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

//-----------------------------tabla alergias--------------------//

  //getAllergysWithPatient
  getAllergys(){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllergys');
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  //crear Alergia:
  setPatientAllergy(  data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setAllergys_getData'); 
    console.log('crear allergy: ', url );
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  updAllergys_getData( id , data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updAllergys_getData') + `?ID=${ id }` ;
    console.log('url upd patient: ', url );
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

//--------------------tabla de enfermedades(diseases)---------------------//

  //getAllergysWithPatient
  getDiseases(){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getDiseases');
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


  //crear Disease:
  setDiseases_getData(  data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setDiseases_getData'); 
    console.log('crear allergy: ', url );
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  updDiseas_getData( id , data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updDiseases_getData') + `?ID=${ id }` ;
    console.log('url upd patient: ', url );
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


//--------------------tabla de tratamientos---------------------//

  //getAllergysWithPatient
  getTreatments(){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getTreatments');
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


  //crear Disease:
  setTreatment_getData(  data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setTreatments_getData'); 
    console.log('crear allergy: ', url );
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  updTreatment_getData( id , data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updTreatments_getData') + `?ID=${ id }` ;
    console.log('url upd patient: ', url );
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }






  
//--------------------tabla de CArgos---------------------//

  //getAllergysWithPatient
  getChargeds(){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getChargeds');
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


  //crear Disease:
  setCharged_getData(  data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setCharged_getData'); 
    console.log('crear allergy: ', url );
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  updCharged_getData( id , data:any ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updCharged_getData') + `?ID=${ id }` ;
    console.log('url upd patient: ', url );
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


  getDiseasesByPatientId( id ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getDiseasesByPatientId') + `?IDPATIENT=${ id }` ;
    console.log('url upd patient: ', url );
    return  this.http.get(url, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  getAllergysByPatientId( id ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAllergysByPatientId') + `?IDPATIENT=${ id }` ;
    console.log('url upd patient: ', url );
    return  this.http.get(url, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  setTreatment_getDataByPatientId(data:any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setTreatment_getPatientTreatment');
    console.log('url upd patient: ', url );
    return  this.http.post(url, data, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  //traer todos los tratamientos
  getTreatmentByPatientId( id ){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getTreatmentByPatientId') + `?idpatient=${ id }` ;
    console.log('url treatment-patient: ', url );
    return  this.http.get(url, { headers: this.headers }).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

    //traer todos los tratamientos
    updTreatmentById( id , data:any ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updTreatments_getSametreatment') + `?ID=${ id }` ;
      console.log('url treatment-patient: ', url );
      return  this.http.post(url,data ,{ headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }
  


    getChargedsByPatientId( id ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getChargedsByPatientId') + `?idpatient=${ id }` ;
      console.log('url treatment-patient: ', url );
      return  this.http.get(url, { headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }


    setcharged_getPatientchargeds(data:any){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setcharged_getPatientchargeds');
      console.log('url upd patient: ', url );
      return  this.http.post(url, data, { headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }

    updChargedById( id , data:any ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updCharged_getSameCharged') + `?ID=${ id }` ;
      console.log('url charged-patient: ', url );
      return  this.http.post(url,data ,{ headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }


    //-------------------distreatments--------------//
    
    getDestreatmentsByPatientId( id ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getDestreatmentsByPatientId') + `?idpatient=${ id }` ;
      console.log('url treatment-patient: ', url );
      return  this.http.get(url, { headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }

    
    setDestreatment_getPatientDestreatments(data:any){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setDestreatment_getPatientDestreatments');
      
      return  this.http.post(url, data, { headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }


    updDestreatment_getSameDestreatment( id , data:any ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updDestreatment_getSameDestreatment') + `?ID=${ id }` ;
      console.log('url charged-patient: ', url );
      return  this.http.post(url,data ,{ headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }

    setImage(archivo){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_uploadImage');
      console.log('url donde sube imagen: ', url );
      
      return this.http.post(url, JSON.stringify(archivo));
    }

  

    setResources( data:any ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setResources_getData');
      console.log('url setear img: ', url );
      return  this.http.post(url, data, { headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }


    getImagesByPatientId( data:any ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getImagesByPatientId');
      console.log('url setear img: ', url );
      return  this.http.post(url, data, { headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }


    updResources( data:any ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updResources');
      console.log('url a actualizar img: ', url );
      return  this.http.post(url, data, { headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }


    //---apointment--//
    getAppointments( request ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_getAppointments')  ;
      console.log('url treatment-patient: ', url );
      return  this.http.post(url, request , { headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }

    setAppointment (data:any ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_setAppointment_getData');
      console.log('url setear img: ', url );
      return  this.http.post(url, data, { headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }

    updAppointment (data:any ){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_updAppointment_getData');
      console.log('url setear img: ', url );
      return  this.http.post(url, data, { headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }

    //-----------------------------report--------------//
    getFollowReport(){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_followReport') ;
      console.log('url reportFollow: ', url );
      return  this.http.get(url, { headers: this.headers }).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );

    }

    getDebtsReport(){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES')+ this.settings.getConstantesByDominioKey('API_URL','api_debtReports') ;
      //const url = "https://api.mocki.io/v1/98896330";
      console.log('url reportFollow: ', url );
      return  this.http.get(url, { headers: this.headers }).pipe(

          map( r => r["data"].filter( res => (res.total_cost - res.total_mount)  !== 0 ) ),


        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      )

    }




}
