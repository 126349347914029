import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  public API_URL: any;
  public app: any;
  public TIEMPOS: any;
  public CONSTANTES: any[];
  public MENU: any[];
  constructor() { 
    this.app = {
      name: 'Prisma',
      description: 'ACS',

      year: ((new Date()).getFullYear()),
      itemPerQueryMobile:10,
      itemPerQueryWeb:10,
      version : '0.0.2',
      timeReviewVersion:'1',
      aplicacion : 'Prisma-ACS',
      urlLogin : '#/',
      urlInicio : '#/',            
      cache : false,
      timeout : 60000,
      flag_aplicacion_web : '1',

      //URL_DOMAIN_SERVICES : 'http://infinito.oracleapexservices.com/apex/dev_sh1dorange61/eCommerceOlimpo/',//Web
      //URL_DOMAIN_SHARED : 'http://infinito.oracleapexservices.com/apex/dev_sh1dorange61/olimpoShared/',//Web
      //URL_DOMAIN_SERVICES : 'localhost/apidental/',//Web


      URL_DOMAIN_SERVICES : 'http://infinitocompany.com/apidental/'

      //URL_DOMAIN_SERVICES : 'http://infinitocompany.com/apidental/',//Web
      //URL_DOMAIN_SERVICES : 'localhost/apidental/'

      //URL_DOMAIN_SERVICES : 'https://inf-prisma.herokuapp.com/',//Web
      //URL_DOMAIN_SERVICES : 'http://agentescomunitarios.org/apiprismaacsc1/'
  };

    this.API_URL = (function(){
      return {
        'api_getHistoryOdontogram':'getHistoryOdontogram',
        'api_updOdontogram_getData':'updOdontogram_getData',
        'api_getOdontogram':'getOdontogram',
        'api_setOdontogram_getData':'setOdontogram_getData',
        'api_getAuthUser' : 'getAuthUser',
        'api_getStablisment' : 'getStablishment',
        'api_setStablisment' : 'setStablishment_getData',
        'api_updStablisment' : 'updStablishment_getData',
        'api_delStablisment' : 'delStablishment_getData',
        'api_getMicronet' : 'getMicronet',
        'api_setMicronet' : 'setMicronet',
        'api_updMicronet' : 'updMicronet',
        'api_delMicronet' : 'delMicronet',
        'api_getNet' : 'getNet',
        'api_setNet' : 'setNet',
        'api_updNet' : 'updNet',
        'api_delNet' : 'delNet',
        'api_getDisa' : 'getDisa',
        'api_setDisa' : 'setDisa_getData',
        'api_updDisa' : 'updDisa_getData',
        'api_delDisa' : 'delDisa_getData',

        'api_setPatient' :'setPatient_getData',
        'api_getPatients':'getPatients',
        'api_updPatient_getData':'updPatient_getData',

        'api_setAllergys_getData':'setAllergys_getData',
        'api_updAllergys_getData':'updAllergys_getData',
        'api_getAllergys':'getAllergys',

        'api_getDiseases':'getDiseases',
        'api_setDiseases_getData':'setDiseases_getData',
        'api_updDiseases_getData':'updDiseases_getData',

        'api_getTreatments':'getTreatments',
        'api_setTreatments_getData':'setTreatments_getData',
        'api_updTreatments_getData':'updTreatments_getData',

        'api_getChargeds':'getChargeds',
        'api_setCharged_getData':'setCharged_getData',
        'api_updCharged_getData':'updCharged_getData',

        'api_getDiseasesByPatientId':'getDiseasesByPatientId',
        'api_getAllergysByPatientId':'getAllergysByPatientId',

        'api_setTreatment_getPatientTreatment':'setTreatment_getPatientTreatment',
        'api_getTreatmentByPatientId':'getTreatmentByPatientId',

        'api_updTreatments_getSametreatment':'updTreatments_getSametreatment',

        'api_getChargedsByPatientId':'getChargedsByPatientId',
        'api_setcharged_getPatientchargeds':'setcharged_getPatientchargeds',
        'api_updCharged_getSameCharged':'updCharged_getSameCharged',

        'api_getDestreatmentsByPatientId':'getDestreatmentsByPatientId',
        'api_setDestreatment_getPatientDestreatments':'setDestreatment_getPatientDestreatments',
        'api_updDestreatment_getSameDestreatment':'updDestreatment_getSameDestreatment',

        'api_setResources_getData':'setResources_getData',
        'api_getImagesByPatientId':'getImagesByPatientId',
        'api_updResources':'updResources_getData',
       
        'api_getAppointments':'getAppointments',
        'api_setAppointment_getData':'setAppointment_getData',
        'api_updAppointment_getData':'updAppointment_getData',

        'api_followReport':'followReport',
        'api_debtReports':'debtReports',
        


        'api_updParameter':'updParameters_getData',
        'api_getUser'                       : 'getUser/',
        'api_getClinicHistory'              : 'getClinicHistory/',
        'api_getAllDiagnostics'             : 'getAllDiagnostics/',
        'api_getAllDiagnosticsByFollow'     : 'getAllDiagnosticsByFollow/',
        'api_getHistoryAnalysis'            : 'getHistoryAnalysis/',
        'api_getDiseasesByIdUser'           : 'getDiseasesByIdUser/',
        'api_getAllHistoryAnalysis'         : 'getAllHistoryAnalysis/',
        'api_getParametersByDomain'         : 'getParametersByDomain/',
        'api_getClinicHistoryByIdUser'      : 'getClinicHistoryByIdUser/',
        'api_getClinicHtaNutritionist'      : 'getClinicHtaNutritionist/',
        'api_updUserHta'                    : 'updUserHta/',
        'api_getUserDiseases'               : 'getUserDiseases/',
        'api_setUserDiseases'               : 'setUserDiseases/',
        'api_updUserDiseases'               : 'updUserDiseases/',
        'api_updClinicHistoryPast'          : 'updClinicHistoryPast/',
        'api_setHistoryAnalysis'            : 'setHistoryAnalysis/',
        'api_updHistoryAnalysis'            : 'updHistoryAnalysis/',
        'api_getAllHistoryFrecuency'        : 'getAllHistoryFrecuency/',
        'api_getHistoryFrecuency'           : 'getHistoryFrecuency/',
        'api_setHistoryFrecuency'           : 'setHistoryFrecuency/',
        'api_updHistoryFrecuency'           : 'updHistoryFrecuency/',
        'api_getHistoryAnamnesis'           : 'getHistoryAnamnesis/',
        'api_getAllHistoryAnamnesis'        : 'getAllHistoryAnamnesis/',        
        'api_setHistoryAnamnesis'           : 'setHistoryAnamnesis/',
        'api_updHistoryAnamnesis'           : 'updHistoryAnamnesis/',
        'api_getAllFoodHabits'              : 'getAllFoodHabits/',
        'api_getFoodHabits'                 : 'getFoodHabits/',
        'api_setFoodHabits'                 : 'setFoodHabits/',
        'api_updFoodHabits'                 : 'updFoodHabits/',
        'api_getHtaFoodHabits'              : 'getHtaFoodHabits/',
        'api_getAllHtaFoodHabits'           : 'getAllHtaFoodHabits/',
        'api_setHtaFoodHabits'              : 'setHtaFoodHabits/',
        'api_updHtaFoodHabits'              : 'updHtaFoodHabits/',
        'api_getHtaPhysicalActivity'        : 'getHtaPhysicalActivity/',
        'api_setHtaPhysicalActivity'        : 'setHtaPhysicalActivity/',
        'api_updHtaPhysicalActivity'        : 'updHtaPhysicalActivity/',
        'api_getHtaClinicalSigns'           : 'getHtaClinicalSigns/',
        'api_setHtaClinicalSigns'           : 'setHtaClinicalSigns/',
        'api_updHtaClinicalSigns'           : 'updHtaClinicalSigns/',
        'api_getFollowHistory'              : 'getFollowHistory/',
        'api_getAllFollowHistory'           : 'getAllFollowHistory/',
        'api_setFollowHistory'              : 'setFollowHistory/',
        'api_updFollowHistory'              : 'updFollowHistory/',
        'api_updFollowHistoryItems'         : 'updFollowHistoryItems/',
        'api_getDataFollowHistoryItems'     : 'getDataFollowHistoryItems/',
        'api_setDiagnosticsNutritionist'    : 'setDiagnosticsNutritionist/',
        'api_getDiagnostics'                : 'getDiagnostics/',
        'api_updDiagnostics'                : 'updDiagnostics/',
        'api_getFollowTopic'                : 'getFollowTopic/',
        'api_getAllFollowTopic'             : 'getAllFollowTopic/',
        'api_setFollowTopic'                : 'setFollowTopic/',
        'api_updFollowTopic'                : 'updFollowTopic/',
        'api_delFollowTopic'                : 'delFollowTopic/',
        'api_getGoalPatient'                : 'getGoalPatient/',
        'api_getAllGoalPatient'             : 'getAllGoalPatient/',
        'api_setGoalPatient'                : 'setGoalPatient/',
        'api_updGoalPatient'                : 'updGoalPatient/',
        'api_delGoalPatient'                : 'delGoalPatient/',
        'api_getReminders'                  : 'getReminders/',
        'api_getAllReminders'               : 'getAllReminders/',
        'api_setReminders'                  : 'setReminders/',
        'api_updReminders'                  : 'updReminders/',
        'api_delReminders'                  : 'delReminders/',
        'api_getRecipes'                    : 'getRecipes/',
        'api_getAllRecipes'                 : 'getAllRecipes',
        'api_getCalcCaloricRequirement'     : 'getCalcCaloricRequirement/',
        'api_getNutritionalPlan'            : 'getNutritionalPlan/',
        'api_getAllNutritionalPlan'         : 'getAllNutritionalPlan/',
        'api_getNutritionalPlanDetail'      : 'getNutritionalPlanDetail/',
        'api_getNutritionalPlanByUserType'  : 'getNutritionalPlanByUserType/',
        'api_getDataNutritionalByUserType'  : 'getDataNutritionalByUserType/',
        'api_getAllNutritionalPlanByUser'   : 'getAllNutritionalPlanByUser/',
        'api_setNutritionalPlanPatient'     : 'setNutritionalPlanPatient/',
        'api_setNutritionalPlan'            : 'setNutritionalPlan/',
        'api_updNutritionalPlanDetail'      : 'updNutritionalPlanDetail/',
        'api_updNutritionalPlan'            : 'updNutritionalPlan/',
        'api_delNutritionalPlan'            : 'delNutritionalPlan/',
        'api_getNutritionalPlanDay'         : 'getNutritionalPlanDay/',
        'api_getAllNutritionalPlanDay'      : 'getAllNutritionalPlanDay/',
        'api_setNutritionalPlanDay'         : 'setNutritionalPlanDay/',
        'api_updNutritionalPlanDay'         : 'updNutritionalPlanDay/',
        'api_delNutritionalPlanDay'         : 'delNutritionalPlanDay/',
        'api_getNutritionalMealDay'         : 'getNutritionalMealDay/',
        'api_getAllNutritionalMealDay'      : 'getAllNutritionalMealDay/',
        'api_setNutritionalMealDay'         : 'setNutritionalMealDay/',
        'api_updNutritionalMealDay'         : 'updNutritionalMealDay/',
        'api_delNutritionalMealDay'         : 'delNutritionalMealDay/',
        'api_setTypeFoodPlan'               : 'setTypeFoodPlan/',
        'api_getAllTypeFoodPlanDetails'     : 'getAllTypeFoodPlanDetails/',
        'api_setTypeFoodPlanDetails'        : 'setTypeFoodPlanDetails/',
        'api_updTypeFoodPlanDetails'        : 'updTypeFoodPlanDetails/',
        'api_delTypeFoodPlanDetails'        : 'delTypeFoodPlanDetails/'
      }
    })();
    this.TIEMPOS = (function(){
      return {
        'time_alert': 4000,
      }
    })();
    this.CONSTANTES = [];
    this.CONSTANTES['APLICACION'] = this.app;
    this.CONSTANTES['API_URL'] = this.API_URL;
    this.CONSTANTES['TIEMPOS'] = this.TIEMPOS;
  }
  getConstantesByDominioKey(dominio, key) {
    if (typeof this.CONSTANTES[dominio] !== 'undefined') {
      return (this.CONSTANTES[dominio])[key];
    }
  }

}
